import { Routes, Route, Navigate } from 'react-router-dom';
import { useState } from 'react';
import { Users } from '../pages/Users';
import Sidebar from './Sidebar';
import Toolbar from './Toolbar';
import { DoctorUsers } from '../pages/DoctorUsers';
import { DoctorProfiles } from '../pages/DoctorProfiles';
import { Appointments } from '../pages/Appointments';
import { Ads } from '../pages/Ads';
import { WebUsers } from '../pages/WebUsers';
import { Cities } from '../pages/Cities';
import Specialities from '../pages/Specialities/Specialities';
import Languages from '../pages/Languages/Languages';
import { Subscription } from '../pages/Subscription';
import Manuals from '../pages/Manuals/Manuals';
import { DocumentCategories } from '../pages/DocumentCategories';
import { Blogs } from '../pages/Blogs';
import Plans from '../pages/plans/Plans';
const Layout = ({ performLogout, userInfo, checkIfLoggedIn }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  if (!userInfo) {
    checkIfLoggedIn();
  }

  return (
    <>
      <div>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} userInfo={userInfo} />
        <div className="flex flex-1 flex-col md:pl-64">
          <Toolbar setSidebarOpen={setSidebarOpen} performLogout={performLogout} userInfo={userInfo} />
          <main className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 min-h-[80vh]">
            <Routes>
              <Route path="/users" element={<Users />} />
              <Route path="/doctor-users" element={<DoctorUsers />} />;
              <Route path="/doctor-profiles" element={<DoctorProfiles userInfo={userInfo} />} />
              <Route path="/appointments" element={<Appointments />} />
              <Route path="/ads" element={<Ads />} />
              <Route path="/cities" element={<Cities />} />
              <Route path="/specialities" element={<Specialities />} />
              <Route path="/languages" element={<Languages />} />
              <Route path='/subscription' element={<Subscription />} />
              <Route path='/manuals' element={<Manuals />} />
              <Route path='/doc-categories' element={<DocumentCategories />} />
              <Route path='/blogs' element={<Blogs />} />
              <Route path='/Plans' element={<Plans />} />
              <Route path="*" element={<Navigate to="/users" />} />
              {userInfo?.idRole === 1 && <Route path="/web-users" element={<WebUsers />} />}
            </Routes>
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
