import { useEffect, useMemo, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { HalfRow } from '../../components';
import FormButton from '../../components/formElements/FormButton';
import { FormFieldsContainer, WhiteCardContainer } from '../../components';
import Loader from '../../components/Loader/Loader';
import { ProfileFormHeader } from '../Users/components/ProfileFormHeader';
import { Blog } from '../../services/Blogs';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table';
import DeleteConfirmationModal from '../../components/alerts/deleteConfirmationModal/DeleteConfirmationModal';
import { Switch } from '@headlessui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const [dialog, setDialog] = useState({ isOpen: false, mode: '' });
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [publishedStates, setPublishedStates] = useState(
        blogs.reduce((acc, blog) => {
            acc[blog.id] = blog.isPublished;
            return acc;
        }, {})
    );
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [blog, setBlog] = useState({ title: '', content: '', author: '' });
    const [imagePreview, setImagePreview] = useState(null);
    const [error, setError] = useState('');

    const [editMode, setEditMode] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setIsModalOpen(false);
        setImagePreview(null);
        setError('');
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];

        if (file && validImageTypes.includes(file.type)) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
                setError('');
            };
            reader.readAsDataURL(file);
        } else {
            setError('Please upload a valid image file (JPEG, PNG, GIF).');
            setImagePreview(null);
        }
        setBlog({ ...blog, image: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { title, author, content, image } = blog;

        const formData = new FormData();
        formData.append('title', title);
        formData.append('author', author);
        formData.append('content', content);
        if (image) {
            formData.append('image', image);
        }
        if (editMode) {
            formData.append('id', blog.id);
        }

        try {
            if (editMode) {
                await Blog.edit(formData);
            } else {
                await Blog.add(formData);
            }
            closeModal();
            setEditMode(false);
            setBlog({ title: '', author: '', content: '', image: null });
            getBlogs();
        } catch (error) {
            console.error('Error adding blog:', error);
            setError('Failed to add blog. Please try again.');
        }
    };

    const handlePublish = async (id) => {
        console.log(publishedStates, 'publishedStates');
        setPublishedStates((prevState) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
        Blog.publish({ id, isPublished: !blogs.find((blog) => blog.id === id).isPublished })
            .then(() => {
                console.log(`Blog ${id} published state updated successfully.`);
                getBlogs();
            })
            .catch((error) => {
                console.error(`Error updating blog ${id} published state:`, error);
            });
    };

    useEffect(() => {
        getBlogs();
    }, []);

    const getBlogs = () => {
        Blog.get().then((res) => {
            setBlogs(res.data);
            setLoading(false);

            const initialPublishedStates = {};
            res.data.forEach((blog) => {
                initialPublishedStates[blog.id] = blog.isPublished;
            });
            setPublishedStates(initialPublishedStates);
        });
    };

    const _handleClick = (selectedItem, mode) => {
        if (mode === 'edit') {
            handleEditClick(selectedItem);
        } else {
            setEditMode(false);
            setBlog({ title: '', author: '', content: '', image: null });
            openModal(mode);
        }
    };

    const handleEditClick = (blog) => {
        setEditMode(true);
        setBlog(blog);
        // setImagePreview(image);
        openModal('edit');
    };

    const _onConfirmDelete = async (id) => {
        await Blog.delete(id);
        setOpenDeleteModal(false);
        getBlogs();
    };

    const truncateHtml = (html, maxLength) => {
        let div = document.createElement('div');
        div.innerHTML = html;
        let truncated = '';
        let length = 0;

        const traverseNodes = (node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                if (length + node.textContent.length > maxLength) {
                    truncated += node.textContent.substring(0, maxLength - length);
                    length = maxLength;
                } else {
                    truncated += node.textContent;
                    length += node.textContent.length;
                }
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                truncated += `<${node.nodeName.toLowerCase()}`;
                for (let attr of node.attributes) {
                    truncated += ` ${attr.name}="${attr.value}"`;
                }
                truncated += '>';
                for (let child of node.childNodes) {
                    if (length >= maxLength) break;
                    traverseNodes(child);
                }
                truncated += `</${node.nodeName.toLowerCase()}>`;
            }
        };

        for (let child of div.childNodes) {
            if (length >= maxLength) break;
            traverseNodes(child);
        }

        return truncated;
    };

    let blogTable = useMemo(() => {
        return blogs.map((item) => {
            return {
                col1: item.title,
                // process.env.REACT_APP_API_SERVER+'/'+item.manualUrl
                col2: item.image ? (
                    <img
                        width={100}
                        height={50}
                        src={process.env.REACT_APP_API_SERVER + '/' + item.image}
                        className="max-w-xs h-auto"
                    />
                ) : (
                    'Not Uploaded'
                ),
                col3: item.author,
                col4: (
                    <div
                        className="whitespace-pre-wrap text-left"
                        dangerouslySetInnerHTML={{ __html: truncateHtml(item.content, 100) }}
                    ></div>
                ),
                col5: new Date(item.creation).toLocaleDateString(),
                col6: (
                    <Switch
                        checked={publishedStates[item.id]}
                        onChange={() => handlePublish(item.id)}
                        className={`${publishedStates[item.id] ? 'bg-blue-600' : 'bg-gray-200'}
                relative inline-flex items-center h-6 rounded-full w-11`}
                    >
                        <span
                            className={`${publishedStates[item.id] ? 'translate-x-6' : 'translate-x-1'}
                    inline-block w-4 h-4 transform bg-white rounded-full`}
                        />
                    </Switch>
                ),
                col7: (
                    <div className="flex gap-2">
                        <div
                            onClick={() => _handleClick(item, 'edit')}
                            className="text-dl-primary-900 hover:text-dl-accent cursor-pointer"
                        >
                            Editar
                            <span className="sr-only"></span>
                        </div>
                        <div
                            onClick={() => setOpenDeleteModal(item.id)}
                            className="text-dl-primary-900 hover:text-dl-accent cursor-pointer"
                        >
                            Borrar
                            <span className="sr-only"></span>
                        </div>
                    </div>
                )
            };
        });
    }, [blogs, publishedStates]);

    const manualColumns = useMemo(() => {
        return [
            { Header: 'Título', accessor: 'col1' },
            { Header: 'Imagen', accessor: 'col2' },
            { Header: 'Autor', accessor: 'col3' },
            { Header: 'Contenido', accessor: 'col4' },
            { Header: 'Fecha de Creación', accessor: 'col5' },
            { Header: 'Publicado', accessor: 'col6' },
            { Header: 'Acciones', accessor: 'col7' }
        ];
    }, []);

    const tableInstance = useTable(
        { columns: manualColumns, data: blogTable },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        data,
        columns,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        nextPage,
        previousPage,
        setPageSize
    } = tableInstance;

    const [value, setValue] = useState(state.globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    if (loading) {
        return <Loader />;
    }

    return (
        <WhiteCardContainer>
            <FormFieldsContainer>
                <ProfileFormHeader title="Blogs" />
                <div className="flex-row-reverse">
                    <FormButton type="button" label="Agregar Nuevo Blog" onClick={openModal} />
                </div>
            </FormFieldsContainer>
            <div className="px-4 sm:px-6 lg:px-8 mb-10">
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table {...getTableProps()} className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th
                                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                    >
                                                        <div className="inline-flex items-center">
                                                            {column.render('Header')}
                                                            <span>
                                                                {column.isSorted ? (
                                                                    column.isSortedDesc ? (
                                                                        <ChevronUpIcon className="h-5 w-5" />
                                                                    ) : (
                                                                        <ChevronDownIcon className="h-5 w-5" />
                                                                    )
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </span>
                                                        </div>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white" {...getTableBodyProps()}>
                                        {page.map((row) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        return (
                                                            <td
                                                                {...cell.getCellProps()}
                                                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                                            >
                                                                {cell.render('Cell')}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <nav
                                    className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                                    aria-label="Pagination"
                                >
                                    <div className="hidden sm:block">
                                        {!columns.some((col) => col.isSorted) && !state.globalFilter && page[0] ? (
                                            <p className="text-sm text-gray-700">
                                                Mostrando desde <span className="font-medium">{page[0].index + 1}</span>{' '}
                                                hasta{' '}
                                                <span className="font-medium">{page[page.length - 1].index + 1}</span>{' '}
                                                de un total de <span className="font-medium">{data.length}</span>{' '}
                                                resultados
                                            </p>
                                        ) : (
                                            <p className="text-sm text-gray-700">
                                                Página <span className="font-medium">{state.pageIndex + 1}</span> de{' '}
                                                <span className="font-medium">{pageCount}</span>
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex flex-1 justify-between sm:justify-end">
                                        <select
                                            id="displayOptions"
                                            onChange={(e) => {
                                                setPageSize(Number(e.target.value));
                                            }}
                                            className="mr-4 relative inline-flex items-center rounded-md mx-1 w-32 border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
                                        >
                                            <option value="10">Mostrar 10</option>
                                            <option value="20">Mostrar 20</option>
                                            <option value="50">Mostrar 50</option>
                                        </select>
                                        <button
                                            type="button"
                                            onClick={() => previousPage()}
                                            disabled={!canPreviousPage}
                                            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
                                        >
                                            Anterior
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => nextPage()}
                                            disabled={!canNextPage}
                                            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
                                        >
                                            Siguiente
                                        </button>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {openDeleteModal && (
                <DeleteConfirmationModal
                    open={Boolean(openDeleteModal)}
                    onClose={() => setOpenDeleteModal(false)}
                    onCancel={() => setOpenDeleteModal(false)}
                    onConfirm={() => _onConfirmDelete(openDeleteModal)}
                    title={'¿Quieres eliminar el blog?'}
                    bodyText={'Esta acción es permanente.'}
                />
            )}
            <Transition appear show={isModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Agregar Nuevo Blog
                                    </Dialog.Title>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mt-2">
                                            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                                                Título
                                            </label>
                                            <input
                                                type="text"
                                                name="title"
                                                id="title"
                                                value={blog.title}
                                                onChange={(e) => setBlog({ ...blog, title: e.target.value })}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="author" className="block text-sm font-medium text-gray-700">
                                                Autor
                                            </label>
                                            <input
                                                type="text"
                                                name="author"
                                                id="author"
                                                value={blog.author}
                                                onChange={(e) => setBlog({ ...blog, author: e.target.value })}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <label
                                                htmlFor="content"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Contenido
                                            </label>
                                            <ReactQuill
                                                value={blog.content}
                                                onChange={(content) => setBlog({ ...blog, content })}
                                                className="quill-editor"
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="image" className="block text-sm font-medium text-gray-700">
                                                Imagen
                                                {console.log(
                                                    imagePreview || blog.image,
                                                    imagePreview,
                                                    blog.image,
                                                    'image'
                                                )}
                                            </label>
                                            <input
                                                type="file"
                                                name="image"
                                                id="image"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                                            />
                                            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                                            {(imagePreview || blog.image) && (
                                                <div className="mt-2 w-full flex justify-center">
                                                    <img
                                                        src={
                                                            imagePreview
                                                                ? imagePreview
                                                                : process.env.REACT_APP_API_SERVER + '/' + blog.image
                                                        }
                                                        alt="Preview"
                                                        className="w-48 h-48 object-cover rounded-lg shadow-lg"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="mt-4 flex justify-end space-x-2">
                                            <FormButton type="submit" label="Guardar" />
                                            <FormButton type="button" label="Cancelar" onClick={closeModal} />
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </WhiteCardContainer>
    );
};

export default Blogs;
