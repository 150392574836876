import { PhoneIcon, CurrencyDollarIcon, MapPinIcon } from '@heroicons/react/24/solid';
import ProfileFormHeader from '../../ProfileFormHeader/ProfileFormHeader';
import FullRow from '../../../../../components/formElements/FullRow';
import HalfRow from '../../../../../components/formElements/HalfRow';
import FormInputField from '../../../../../components/formElements/FormInputField';
import FormTextArea from '../../../../../components/formElements/FormTextArea';
import FormSelectDrop from '../../../../../components/formElements/FormSelectDrop';
import FormButton from '../../../../../components/formElements/FormButton';
import CustomRedAlert from '../../../../../components/alerts/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import useInitialForm from './hooks/useInitialForm';
import FormFieldset from '../../../../../components/formElements/FormFieldset';
import FormCheckBox from '../../../../../components/formElements/FormCheckBox';
import { useRef } from 'react';
import WhiteCardContainer from '../../../../../components/containers/forms/WhiteCardContainer';
import FormFieldsContainer from '../../../../../components/containers/forms/FormFieldsContainer';
import FormFieldsFooter from '../../../../../components/containers/forms/FormFieldsFooter';

export default function InitialProfileForm(props) {
    const { cities, basicProfile, specialities, queryBasicProfile, selectedUser, fetchData } = props;

    const {
        showErrorModal,
        closeErrorMessage,
        showSuccessModal,
        successBody,
        errorBody,
        register,
        handleSubmit,
        errors,
        watch,
        formattedCities,
        formattedSpecialities,
        // isLoading,
        onCloseSuccessMessage,
        saveProfileForm,
        isEditing,
        defaultValues
    } = useInitialForm(basicProfile, cities, specialities, queryBasicProfile, selectedUser, fetchData);

    const transfersRef = useRef(false);
    const remesaRef = useRef(false);
    const bitcoinRef = useRef(false);
    const cashRef = useRef(true);
    const creditCardRef = useRef(false);

    transfersRef.current = watch('transfers', false);
    remesaRef.current = watch('remesa', false);
    bitcoinRef.current = watch('bitcoin', false);
    cashRef.current = watch('cash', true);
    creditCardRef.current = watch('creditCard', false);

    const _validateNumbers = (value) => {
        return parseInt(value) > 0;
    };

    const _validateNonRequiredNumbers = (value) => {
        if (value === '') {
            return;
        }
        return parseInt(value) > 0;
    };

    const _validateNonRequiredNumbersExamCost = (value) => {
        if (value === '') {
            return;
        }
        return parseInt(value) >= 0;
    };

    const _paymentMethodsValidations = () => {
        return cashRef.current || creditCardRef.current || transfersRef.current || bitcoinRef.current || remesaRef.current;
    };

    return (
        <form action="#" method="POST" onSubmit={handleSubmit(saveProfileForm)}>
            <WhiteCardContainer>
                <FormFieldsContainer>
                    <ProfileFormHeader
                        title={'Bienvenido Doctor'}
                        initialText={'Ingresa la siguiente información para iniciar con tu perfil.'}
                        secondaryText={
                            'Una vez guardado este registro podrás acceder a la información adicional e iniciar el\n' +
                            '                            proceso de validación de perfil.'
                        }
                    />

                    <div className="grid grid-cols-6 gap-6">
                        <HalfRow>
                            <FormInputField
                                identifier={'patientContactPhone1'}
                                label={'Teléfono Principal'}
                                iconComponent={<PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type={'tel'}
                                placeholder={'Ej. 2257-7777'}
                                helperText={'Requerido'}
                                registration={register('patientContactPhone1', { required: true })}
                                errorMessage={errors.patientContactPhone1 ? 'Este campo es requerido' : null}
                            />
                        </HalfRow>

                        <HalfRow>
                            <FormInputField
                                identifier={'patientContactPhone2'}
                                label={'Teléfono Secundario'}
                                iconComponent={<PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type={'tel'}
                                placeholder={'Ej. 2273-6000'}
                                registration={register('patientContactPhone2')}
                            />
                        </HalfRow>

                        <HalfRow>
                            <FormInputField
                                identifier={'consultationCost'}
                                label={'Costo de consulta'}
                                iconComponent={
                                    <CurrencyDollarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                }
                                type={'number'}
                                placeholder={'Ej. 22.40'}
                                helperText={'Requerido'}
                                step={'any'}
                                registration={register('consultationCost', {
                                    required: true,
                                    min: 0.0,
                                    validate: _validateNumbers
                                })}
                                errorMessage={
                                    errors.consultationCost
                                        ? 'Este campo es requerido. Debe ser un número positivo.'
                                        : null
                                }
                            />
                        </HalfRow>

                        <HalfRow>
                            <FormInputField
                                identifier={'examCost'}
                                label={'Costo de examen'}
                                iconComponent={
                                    <CurrencyDollarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                }
                                type={'number'}
                                placeholder={'Ej. 10.00'}
                                step={'any'}
                                registration={register('examCost', {
                                    min: 0.0,
                                    validate: _validateNonRequiredNumbersExamCost
                                })}
                                errorMessage={errors.examCost ? 'Debe ser un número positivo.' : null}
                            />
                        </HalfRow>

                        <FormFieldset
                            legend={'Métodos de pago'}
                            errorMessage={
                                !cashRef.current && !creditCardRef.current && !transfersRef.current && !bitcoinRef.current && !remesaRef.current ? 'Debe elegir al menos una opción' : null
                            }
                        >
                            <FormCheckBox
                                identifier={'cash'}
                                checked={false}
                                label={'Efectivo'}
                                registration={register('cash', { validate: _paymentMethodsValidations })}
                            />
                            <FormCheckBox
                                identifier={'creditCard'}
                                checked={false}
                                label={'Tarjeta de Crédito'}
                                registration={register('creditCard', { validate: _paymentMethodsValidations })}
                            />
                            <FormCheckBox
                                identifier={'transfers'}
                                checked={false}
                                label={'Transferencias'}
                                registration={register('transfers', { validate: _paymentMethodsValidations })}
                            />
                            <FormCheckBox
                                identifier={'bitcoin'}
                                checked={false}
                                label={'Bitcoin'}
                                registration={register('bitcoin', { validate: _paymentMethodsValidations })}
                            />
                            <FormCheckBox
                                identifier={'remesa'}
                                checked={false}
                                label={'Remesa'}
                                registration={register('remesa', { validate: _paymentMethodsValidations })}
                            />
                        </FormFieldset>

                        <FullRow>
                            <FormTextArea
                                label={'Permiso para ejercer'}
                                identifier={'governmentPermitInfo'}
                                rows={3}
                                placeholder={
                                    'Ej. Número de junta de vigilancia de la profesión No.123, inscrito como doctor el 27 de julio de 2005'
                                }
                                helperText={'Información relevante sobre el permiso para ejercer como médico.'}
                                registration={register('governmentPermitInfo')}
                            />
                        </FullRow>

                        <FullRow>
                            <FormTextArea
                                label={'Información general de consulta.'}
                                identifier={'generalInfo'}
                                rows={3}
                                placeholder={
                                    'Ej. Mi consultorio es el número 3. Si viene por primera vez traiga una radiografía y un examen de sangre.'
                                }
                                helperText={'Información relevante para el paciente que le visita.'}
                                registration={register('generalInfo')}
                            />
                        </FullRow>

                        <FullRow>
                            <FormInputField
                                label={'Aseguradoras con las que trabaja'}
                                type={'text'}
                                identifier={'insurance'}
                                placeholder={'Ej. ACSA, Mapfre, Asesuiza, SISA'}
                                helperText={'Separados por coma. Si no trabaja con ninguna dejar en blanco.'}
                                registration={register('insurance')}
                            />
                        </FullRow>

                        <FullRow>
                            <FormInputField
                                label={'Dirección del consultorio'}
                                type={'text'}
                                identifier={'address'}
                                autoComplete={'street-address'}
                                registration={register('address', { required: true })}
                                errorMessage={errors.address ? 'Este campo es requerido' : null}
                            />
                        </FullRow>

                        {/*  LAT LONG */}

                        <FullRow>
                            <FormInputField
                                identifier={'latitudeAndLongitude'}
                                label={'Latitud y longitud'}
                                iconComponent={<MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                type={'text'}
                                placeholder="Ej. 13.699629843071902, -89.22413229268238"
                                helperText={
                                    <>
                                        {' '}
                                        Si necesita ayuda, lea este{' '}
                                        <a
                                            target="_blank"
                                            rel="noopener nofollow noreferrer"
                                            className="text-dl-primary-500"
                                            href="https://support.google.com/maps/answer/18539?co=GENIE.Platform%3DDesktop&hl=es-MX#:~:text=C%C3%B3mo%20obtener%20las%20coordenadas%20de,decimal%20en%20la%20parte%20superior."
                                        >
                                            artículo sobre como sacar coordenadas de Google Maps.
                                        </a>{' '}
                                    </>
                                }
                                registration={register('latitudeAndLongitude')}
                            />
                        </FullRow>

                        {/* END  LAT LONG */}

                        {/* CITY */}

                        <FullRow>
                            <FormSelectDrop
                                label={'Ciudad'}
                                autoComplete={'city'}
                                identifier={'idCity'}
                                options={formattedCities}
                                defaultValue={defaultValues.idCity}
                                helperText={'Requerido'}
                                registration={register('idCity', { required: true })}
                                errorMessage={errors.idCity ? 'Este campo es requerido' : null}
                            />
                        </FullRow>
                        {/* END CITY */}

                        {/* SPECIALITY */}

                        <FullRow>
                            <FormSelectDrop
                                label={'Especialidad Principal'}
                                identifier={'idSpecialty'}
                                options={formattedSpecialities}
                                defaultValue={defaultValues.idSpecialty}
                                helperText={'Requerido'}
                                registration={register('idSpecialty', { required: true })}
                                errorMessage={errors.idSpecialty ? 'Este campo es requerido' : null}
                            />
                        </FullRow>
                        {/* END SPECIALITY */}
                    </div>
                </FormFieldsContainer>
                <FormFieldsFooter>
                    <FormButton label={isEditing ? 'Actualizar' : 'Guardar'} type={'submit'} />
                </FormFieldsFooter>
            </WhiteCardContainer>
            {showErrorModal && (
                <CustomRedAlert
                    open={true}
                    title={`Ocurrió un error.`}
                    bodyText={errorBody}
                    onClose={closeErrorMessage}
                />
            )}

            {showSuccessModal && (
                <CustomSuccessModal
                    title={isEditing ? 'Perfil actualizado con éxito' : 'Perfil inicial guardado con éxito'}
                    bodyText={successBody}
                    buttonText={'Actualizar Perfil'}
                    buttonAction={onCloseSuccessMessage}
                    open={showSuccessModal}
                    onClose={onCloseSuccessMessage}
                />
            )}
        </form>
    );
}
