import Axios from './Axios';

const API_URL = '/blogs/';

export const Blog = {
    get: () => Axios.get(API_URL + `list`),
    add: (payload) => Axios.post(API_URL + 'add', payload),
    edit: (payload) => Axios.patch(API_URL + 'update', payload),
    publish: (payload) => Axios.put(API_URL + 'publish', payload),
    delete: (id) => Axios.delete(API_URL + id)
}