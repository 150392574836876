import Axios from './Axios';

const API_URL = '/plans/characteristics';

export const planCharacteristic = {
    get: () => Axios.get(API_URL),
    add: (payload) => Axios.post(API_URL, payload),
    edit: (payload) => Axios.put(`${API_URL}/${payload.id}`, payload), 
    delete: (id) => Axios.delete(`${API_URL}/${id}`) 
};

