import Axios from './Axios';

const API_URL = '/plans/';

export const plan = {
    get: () => Axios.get(API_URL + 'list'),
    add: (payload) => Axios.post(API_URL + 'add', payload), 
    edit: (payload) => Axios.patch(API_URL + 'update', payload), 
    delete: (id) => Axios.delete(API_URL + id) 
};



